import React, { useCallback, useEffect } from 'react';
import { AnyObject } from '@/types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, DialogActions, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

type ButtonProps = React.ComponentProps<typeof Button>;
type TooltipProps = React.ComponentProps<typeof Tooltip>;
export type ButtonWithTooltipProps = ButtonProps & { tooltip?: string | JSX.Element; tooltipProps?: TooltipProps | AnyObject };

export const ButtonWithTooltip = (props: ButtonWithTooltipProps) => {
  const button = <Button {...(_.omit(props, 'tooltip', 'tooltipProps'))} />;
  const hasTooltip = !!props.tooltip;
  const contents = (hasTooltip && props.disabled) ? <Typography>{button}</Typography> : button;

  return hasTooltip
    ? (
      <Tooltip title={props.tooltip} {...props.tooltipProps}>
        {contents}
      </Tooltip>
      )
    : contents;
};

export const CancelDialogButton = (props: ButtonWithTooltipProps) => {
  return <ButtonWithTooltip
    {...props}
    variant='text'>
    Cancel
  </ButtonWithTooltip>;
};

export const DeleteDialogButton = (props: ButtonWithTooltipProps) => {
  const label = <><DeleteOutlineIcon sx={{ marginRight: 2 }} />Delete</>;

  return <ButtonWithTooltip
    {...props}
    className={props.disabled ? 'disabled-delete' : ''}
    color='error'
    variant='outlined'>
    {label}
  </ButtonWithTooltip>;
};

type DefaultDialogButtonProps = ButtonWithTooltipProps & { label?: string };
export const DefaultDialogButton = (props: DefaultDialogButtonProps) => {
  return <ButtonWithTooltip
    variant='contained'
    {...props}>
    {props.label ?? 'Save'}
  </ButtonWithTooltip>;
};

type StandardDialogActionsProps = {
  className?: string;
  deleteProps?: ButtonWithTooltipProps;
  cancelProps?: ButtonWithTooltipProps;
  defaultButtonProps?: DefaultDialogButtonProps;
  children?: React.ReactNode;
};

/**
 * A wrapper for DialogActions that may include a delete button, a cancel button, and a default button, as well as optional
 * children which appear to the right of the delete button and to the left of the cancel button.
 */
export const StandardDialogActions: React.FC<StandardDialogActionsProps> =
  ({ deleteProps, cancelProps, defaultButtonProps, children, className }) => {
    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter' && defaultButtonProps?.onClick) {
          event.preventDefault();
          defaultButtonProps.onClick(null);
        }
        if (event.key === 'Escape' && cancelProps?.onClick) {
          event.preventDefault();
          cancelProps.onClick(null);
        }
      }, [defaultButtonProps, cancelProps]);

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    });

    const buttonStyle: React.CSSProperties = { margin: '0.5rem' };
    return <DialogActions className={`StandardDialogActions bottom-bar ${className}`}>
      {deleteProps && <DeleteDialogButton {...deleteProps} style={buttonStyle} />}
      <div style={{ flex: 1 }}>
        {children}
      </div>
      {cancelProps && <CancelDialogButton {...cancelProps} style={buttonStyle} />}
      {defaultButtonProps && <DefaultDialogButton {...defaultButtonProps} style={buttonStyle} />}
    </DialogActions>;
  };
