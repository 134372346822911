/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import './Mixture.sass';
import { observer } from 'mobx-react';
import { ArrowKeyDirection, MixturesStore } from './mixturesStore';
import { UnderlayNode } from './UnderlayNode';
import { OverlayNode } from './OverlayNode';
import { A } from '@/shared/components/sanitizedTags.js';
import { Tooltip, Typography } from '@mui/material';

export type Props = {
  store: MixturesStore,
  width: number,
  height: number,
};
type State = {
  isDragging: boolean;
  lastX: number;
  lastY: number;
}

const DEFAULT_POINTSCALE = 25;
const ZOOM_MAGUNIT = 1.25;

@observer
export class MixtureEditorPanel extends React.Component<Props, State> {
  private divMain: HTMLDivElement = null;
  private monochrome = true;
  private lastWatermarkMixture = -1;
  private layout: Mixtures.ArrangeMixture;
  private policy: WebMolKit.RenderPolicy;
  private pointScale = 0;
  private offsetX = 0;
  private offsetY = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      isDragging: false,
      lastX: 0,
      lastY: 0,
    };
  }

  public render(): JSX.Element {
    const { store, width, height } = this.props;
    const { zoomPanEvents, mixture, parsing } = store;

    const isFirst = this.pointScale == 0;
    if (isFirst) this.pointScale = DEFAULT_POINTSCALE;

    if (store.watermarkMixture != this.lastWatermarkMixture) {
      this.lastWatermarkMixture = store.watermarkMixture;
      this.layout = null;
      // (maybe rescale...)
    }

    if (!this.layout) {
      const thenScale = this.pointScale;
      for (const zpe of zoomPanEvents) {
        if (zpe.zoomDirection) this.pointScale *= Math.pow(ZOOM_MAGUNIT, zpe.zoomDirection);
        if (zpe.panDX) this.offsetX += zpe.panDX;
        if (zpe.panDY) this.offsetY += zpe.panDY;
        if (zpe.resizeToFit) this.pointScale = DEFAULT_POINTSCALE;
      }

      const measure = new WebMolKit.OutlineMeasurement(0, 0, this.pointScale);
      this.policy = this.monochrome ? WebMolKit.RenderPolicy.defaultBlackOnWhite(this.pointScale) : WebMolKit.RenderPolicy.defaultColourOnWhite(this.pointScale);
      this.layout = new Mixtures.ArrangeMixture(mixture, measure, this.policy);
      if (mixture.isEmpty()) this.layout.minBoxSize = new WebMolKit.Size(100, 100);
      this.layout.showCollapsors = true;
      this.layout.collapsedBranches = store.collapsedBranches;
      // (leaving this off for now) this.layout.packBranches = new WebMolKit.Size(0.8 * width, 0.8 * height);
      this.layout.includeIdentifiers = false;
      this.layout.norm = new Mixtures.NormMixture(mixture);
      this.layout.norm.analyse();
      this.layout.arrange();

      if (isFirst || zoomPanEvents.some((zpe) => zpe.resizeToFit)) {
        this.scaleToFit(true);
      } else if (thenScale != this.pointScale) {
        const nowW = this.layout.width, thenW = nowW * thenScale / this.pointScale;
        const nowH = this.layout.height, thenH = nowH * thenScale / this.pointScale;
        this.offsetX -= 0.5 * (nowW - thenW);
        this.offsetY -= 0.5 * (nowH - thenH);
      }

      if (zoomPanEvents.length > 0) {
        setTimeout(() => {
          store.clearZoomPanEvents();
        }, 1);
      }
    }

    const gfx = new WebMolKit.MetaVector();
    const draw = new Mixtures.DrawMixture(this.layout, gfx);
    const nameLinks: JSX.Element[] = [];
    draw.callbackDrawNameLine = (comp, line, gfx, box) => {
      const url = comp.content.links?.vaultMolecule as string, batch = comp.content.identifiers?.vaultBatch as string;

      let payload: JSX.Element, pointerEvents: 'none' | 'auto' = 'none';

      if (line.source == Mixtures.ArrangeMixtureLineSource.Name && url) {
        pointerEvents = 'auto';
        const tooltipLink = (
          <Typography>
            Click on link to see registered molecule.
          </Typography>
        );

        payload = (
          <Tooltip
            title={tooltipLink}
            arrow
            disableFocusListener
            placement="right"
            >
            <A
              href={url}
              target="_blank"
              style={{ maxWidth: `${box.w}px`, overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
              {line.text}{batch && `-${batch}`}
            </A>
          </Tooltip>
        );
      } else {
        payload = (
          <div>{line.text}</div>
        );
      }

      const nudgeUp = comp.molBox.h == 0 ? 3 : 0;
      nameLinks.push((
        <div
          key={`namelink-${nameLinks.length}`}
          className="MixtureEditor-namelink"
          style={{
            left: `${this.offsetX + box.x}px`,
            top: `${this.offsetY + box.y - nudgeUp}px`,
            width: `${box.w}px`,
            height: `${box.h}px`,
            fontSize: `${comp.fontSize}px`,
            pointerEvents,
          }}
          >
          {payload}
        </div>
      ));

      return true;
    };
    draw.draw();

    gfx.offsetX = this.offsetX;
    gfx.offsetY = this.offsetY;
    gfx.setSize(width, height);
    const svg = gfx.createSVG();

    const underlays = this.layout.components.map((arrcomp, idx) => {
      return (
        <UnderlayNode
          store={store}
          key={`underlay-${idx}`}
          arrcomp={arrcomp}
          offsetX={this.offsetX}
          offsetY={this.offsetY}
          />
      );
    });
    const overlays = this.layout.components.map((arrcomp, idx) => {
      return (
        <OverlayNode
          store={store}
          key={`overlay-${idx}`}
          arrcomp={arrcomp}
          offsetX={this.offsetX}
          offsetY={this.offsetY}
          width={width}
          height={height}
          />
      );
    });

    return (
      <div
        className="MixtureEditor-panel-parent"
        tabIndex={0}
        ref={(element) => { this.divMain = element; }}
        style={{ width: `${width}px`, height: `${height}px` }}
        onClick={() => { store.setMenuOrigin(null); store.setSelectedOrigin(null); }}
        onContextMenu={(event) => { event.preventDefault(); store.setMenuOrigin([-1]); }}
        onKeyDown={this.handleKeyDown}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseOut={this.handleMouseOut}
        onDoubleClick={this.handleDoubleClick}
        >
        {underlays}
        <div
          key="mixturepanel-main"
          className="MixtureEditor-panel-child"
          dangerouslySetInnerHTML={{ __html: svg }}
          />
        {parsing && nameLinks}
        {overlays}
        {!parsing && nameLinks}
        <span style={{ display: 'none' }}>{store.parsing?.text}</span>
      </div>
    );
  }

  handleMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ isDragging: true, lastX: event.clientX, lastY: event.clientY });
  };

  handleMouseMove = (event: React.MouseEvent<HTMLElement>): void => {
    const { isDragging, lastX, lastY } = this.state;
    if (!isDragging || (lastX == event.clientX && lastY == event.clientY)) return;

    this.props.store.actionPanDisplay(event.clientX - lastX, event.clientY - lastY);
    this.setState({
      lastX: event.clientX,
      lastY: event.clientY,
    });
  };

  handleMouseUp = (event: React.MouseEvent<HTMLElement>): void => { // eslint-disable-line @typescript-eslint/no-unused-vars
    this.setState({ isDragging: false });
  };

  handleMouseOut = (event: React.MouseEvent<HTMLElement>): void => { // eslint-disable-line @typescript-eslint/no-unused-vars
    this.setState({ isDragging: false });
  };

  handleDoubleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    event.preventDefault();
  };

  componentDidMount(): void {
    this.divMain.focus();
  }

  componentDidUpdate(): void {
    if (!this.props.store.parsing) {
      this.divMain.focus();
    }
  }

  private scaleToFit(mustFit: boolean): void {
    const { width, height } = this.props;
    const pad = 10;
    if (mustFit) {
      if (this.layout.width > width - pad || this.layout.height > height - pad) {
        const scale = Math.min((width - pad) / this.layout.width, (height - pad) / this.layout.height);
        this.pointScale *= scale;
        this.layout.scaleComponents(scale);
      }
      this.offsetX = 0.5 * (width - this.layout.width);
      this.offsetY = 0.5 * (height - this.layout.height);
    } else {
      this.offsetX = Math.max(pad, 0.5 * (width - this.layout.width));
      this.offsetY = 0.5 * (height - this.layout.height);
    }
  }

  private handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    const { store } = this.props;
    const { key, shiftKey, ctrlKey, altKey, metaKey } = event;
    const mods = (shiftKey ? 'S' : '') + (ctrlKey ? 'C' : '') + (altKey ? 'A' : '') + (metaKey ? 'M' : '');
    const xmods = (ctrlKey || metaKey ? 'X' : '') + (shiftKey ? 'S' : '') + (altKey ? 'A' : ''); // for Mac vs. PC: ctrl/shift = X

    if (store.menuOrigin) {
      return;
    }

    // console.log('KEY: ' + key + ' mods: ' + xmods);

    const origin = store.selectedOrigin;

    let stop = true;
    if (key == 'Enter' && mods == '') {
      if (origin) store.actionEditStructure(origin);
    } else if (key == 'Enter' && mods == 'S') {
      if (origin) store.actionEditDetails(origin);
    } else if (key == 'Escape' && mods == '') {
      // ...
    } else if (key == ' ' && mods == '') {
      store.actionActivateTyping('');
    } else if (key == 'Tab' && mods == '') {
      store.actionKeyTab();
    } else if (key == 'ArrowUp' && mods == '') {
      store.actionKeyArrow(ArrowKeyDirection.Up);
    } else if (key == 'ArrowDown' && mods == '') {
      store.actionKeyArrow(ArrowKeyDirection.Down);
    } else if (key == 'ArrowLeft' && mods == '') {
      store.actionKeyArrow(ArrowKeyDirection.Left);
    } else if (key == 'ArrowRight' && mods == '') {
      store.actionKeyArrow(ArrowKeyDirection.Right);
    } else if (key == '\\' && xmods == 'X') {
      if (origin) store.actionInsertLeft(origin);
    } else if (key == '/' && xmods == 'X') {
      if (origin) store.actionInsertRight(origin);
    } else if (key == ';' && xmods == 'X') {
      if (origin) store.actionInsertAbove(origin);
    } else if (key == '\'' && xmods == 'X') {
      if (origin) store.actionInsertBelow(origin);
    } else if (key == 'ArrowUp' && xmods == 'X') {
      if (origin) store.actionMove(origin, -1);
    } else if (key == 'ArrowDown' && xmods == 'X') {
      if (origin) store.actionMove(origin, 1);
    } else if (key == 'Delete' && xmods == '') {
      if (origin) store.actionDelete(origin, false);
    } else if (key == 'Delete' && xmods == 'X') {
      if (origin) store.actionDelete(origin, true);
    } else if (key == 'c' && xmods == 'X') {
      if (origin) store.actionCopy(origin, false);
    } else if (key == 'c' && xmods == 'XS') {
      if (origin) store.actionCopy(origin, true);
    } else if (key == 'x' && xmods == 'X') {
      if (origin) store.actionCut(origin);
    } else if (key == 'z' && xmods == 'X') {
      store.actionUndo();
    } else if (key == 'z' && xmods == 'XS') {
      store.actionRedo();
    } else if (!ctrlKey && !altKey && !metaKey && key.length == 1) {
      store.actionActivateTyping(key);
    } else {
      // it's a system key that we want to pass through
      stop = false;
    }

    if (stop) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
}
