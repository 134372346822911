import { MixtureParsedResult, MixtureParsedResultType, MixtureParsing, MixturesStore } from './mixturesStore';
import { searchTheVaultAsync } from '@/shared/utils/mixtureLookupUtils.js';

export class ProcessParsingText {
  private text: string;
  private watermarkExec: number;
  private bounceExec = 0;

  private policy = WebMolKit.RenderPolicy.defaultBlackOnWhite(15);

  constructor(private store: MixturesStore, private parsing: MixtureParsing) {
    this.text = parsing.text;
    this.watermarkExec = parsing.watermarkExec;
  }

  public execute(): void {
    const keyBounce = this.parsing.lastKeyBounce = ++this.parsing.nextKeyBounce;

    setTimeout(() => {
      if (keyBounce != this.parsing.lastKeyBounce) return; // skip it, already typed another key

      this.upBounce();

      this.executeTask(async () => await this.produceBasic());
      this.executeTask(async () => await this.producePredicted());
      this.executeTask(async () => await this.produceReference());
      this.executeTask(async () => await this.produceVault());

      this.downBounce();
    }, 200);
  }

  private executeTask(task: () => Promise<MixtureParsedResult[]>): void {
    this.upBounce();

    (async () => {
      const newResults = await task();
      if (this.beenCancelled()) return;
      this.appendParsingResults(newResults);
      this.downBounce();
    })();
  }

  private upBounce(): void {
    this.bounceExec++;
  }

  private downBounce(): void {
    this.bounceExec--;
    if (this.bounceExec == 0) {
      this.store.notifyParsingDone();
    }
  }

  private beenCancelled(): boolean {
    return this.parsing.isCancelled || this.parsing.watermarkExec != this.watermarkExec;
  }

  private appendParsingResults(newResults: MixtureParsedResult[]): void {
    if (this.beenCancelled() || newResults.length == 0) return;

    const results = [...this.parsing.results, ...newResults];
    for (const result of results) {
      if (result.sortPri != null) continue;

      result.sortPri = 100;
      const mixtureName = result.comp.name;
      if (result.type == MixtureParsedResultType.Vault) result.sortPri = 0;
      else if (result.type == MixtureParsedResultType.Quantity) result.sortPri = 0.5;
      else if (result.type == MixtureParsedResultType.Predicted) result.sortPri = 1.1;
      else if (mixtureName) result.sortPri = Mixtures.stringSimilarity(this.text, mixtureName) + 1;
    }
    results.sort((r1, r2) => r1.sortPri - r2.sortPri);

    const MAX_RESULTS = 50;
    if (results.length > MAX_RESULTS) {
      results.splice(MAX_RESULTS, results.length);
    }

    for (let i = results.length - 1; i > 0; i--) {
      for (let j = 0; j < i; j++) {
        if (this.degenerateMixtures(results[i].comp, results[j].comp)) {
          results.splice(i, 1);
          break;
        }
      }
    }

    this.store.changeParsingResults(results);
  }

  private degenerateMixtures(comp1: Mixtures.MixfileComponent, comp2: Mixtures.MixfileComponent): boolean {
    if (!!comp1.molfile != !!comp2.molfile) return false; // structures are assumed equal if present

    const dict1: any = comp1, dict2: any = comp2; // eslint-disable-line @typescript-eslint/no-explicit-any
    const keys1: string[] = [], keys2: string[] = [];
    for (const k in dict1) if (k != 'molfile' && k != 'contents' && dict1[k] != null) keys1.push(k);
    for (const k in dict2) if (k != 'molfile' && k != 'contents' && dict2[k] != null) keys2.push(k);
    keys1.sort();
    keys2.sort();
    if (!WebMolKit.Vec.equals(keys1, keys2)) return false; // different keys (less contents) is a dealbreaker
    for (const k of keys1) {
      let v1 = dict1[k], v2 = dict2[k];
      if (Array.isArray(v1) && Array.isArray(v2)) {
        if (!WebMolKit.Vec.equals(v1, v2)) return false;
      } else { // assume scalar
        if (k == 'name') {
          if (v1) v1 = (v1 as string).toLowerCase();
          if (v2) v2 = (v2 as string).toLowerCase();
        }
        if (v1 != v2) return false;
      }
    }

    const len = WebMolKit.Vec.arrayLength(comp1.contents);
    if (len != WebMolKit.Vec.arrayLength(comp2.contents)) return false;
    for (let n = 0; n < len; n++) if (!this.degenerateMixtures(comp1.contents[n], comp2.contents[n])) return false;

    return true;
  }

  private async produceBasic(): Promise<MixtureParsedResult[]> {
    // first see if it looks like a quantity description, and use that preferentially
    const pq = new Mixtures.ParseQuantity(this.text).parse();
    if (pq) {
      const result: MixtureParsedResult = {
        key: 'quantity',
        type: MixtureParsedResultType.Quantity,
        comp: pq as Mixtures.MixfileComponent,
      };
      this.fillGraphics(result);
      return [result];
    }

    // create a mixture with just the name
    const result: MixtureParsedResult = {
      key: 'basic',
      type: MixtureParsedResultType.Basic,
      comp: { name: this.text },
    };
    this.fillGraphics(result);
    return [result];
  }

  private async producePredicted(): Promise<MixtureParsedResult[]> {
    const ALLOW_FIELDS = ['name', 'formula', 'molfile', 'quantity', 'error', 'ratio', 'units', 'relation', 'identifiers', 'links', 'contents', 'synonyms'];

    const sanitizeMixture = (comp: Mixtures.MixfileComponent) => {
      // only allow indicated fields at the root level: other information may be relevant, may reconsider later
      for (const k in comp) {
        if (!ALLOW_FIELDS.includes(k)) delete (comp as any)[k]; // eslint-disable-line @typescript-eslint/no-explicit-any
      }
      for (const sub of comp.contents ?? []) {
        sanitizeMixture(sub);
      }
    };

    const { fauxHost } = this.store;

    const mixfile = await fauxHost.performPredict(this.text);
    if (mixfile.mixfileVersion && (mixfile.molfile || WebMolKit.Vec.notBlank(mixfile.contents) || mixfile.quantity != null)) {
      sanitizeMixture(mixfile);
      const result: MixtureParsedResult = {
        key: 'predicted',
        type: MixtureParsedResultType.Predicted,
        comp: mixfile,
      };
      this.fillGraphics(result);
      return [result];
    }

    return [];
  }

  private async produceReference(): Promise<MixtureParsedResult[]> {
    const { fauxHost } = this.store;
    const depotNames = await fauxHost.getDepotNames();

    for (let idx = 0; idx < depotNames.length; idx++) {
      this.upBounce();
      (async () => {
        const mixfiles = await fauxHost.performSearchMixture(this.text, idx);
        if (this.beenCancelled()) return;
        const results: MixtureParsedResult[] = mixfiles.map((mixfile, ridx) => {
          return {
            key: `reference-${idx}-${ridx}`,
            type: MixtureParsedResultType.Reference,
            comp: mixfile,
          };
        });
        for (const result of results) this.fillGraphics(result);
        this.appendParsingResults(results);
        this.downBounce();
      })();
    }

    return []; // results are appended as they come in, in the above loop
  }

  private async produceVault(): Promise<MixtureParsedResult[]> {
    const vaultMatches = await searchTheVaultAsync(this.text);
    return vaultMatches.map((vault, idx) => {
      const mixture = vault.mixture as Mixtures.Mixture;
      const synonyms = (vault.synonyms ?? []).filter((syn) => syn != mixture.mixfile.name);
      if (synonyms.length > 0) {
        mixture.mixfile.synonyms = synonyms;
      }

      const molName = vault.molName as string;
      const batches = vault.batches as string[];
      const result: MixtureParsedResult = {
        key: `vault-${idx}`,
        type: MixtureParsedResultType.Vault,
        comp: mixture.mixfile,
        vaultMolName: molName,
        vaultBatches: batches,
        vaultSynonyms: vault.synonyms,
      };
      this.fillGraphics(result);
      return result;
    });
  }

  private fillGraphics(result: MixtureParsedResult): void {
    const mixture = Mixtures.Mixture.fromComponent(result.comp).clone();

    // any instances with a really big molfile get subbed out with a can't-render message
    // TODO: reevaluate this after making some improvements to the rendering engine
    const MOL_LIMIT = 35000;
    for (const comp of mixture.getComponents()) {
      if (comp.molfile?.length > MOL_LIMIT) {
        delete comp.molfile;
        if (comp.name) {
          comp.synonyms = [comp.name, ...(comp.synonyms ?? [])];
          comp.name = '(molecule too big)';
        }
      }
    }

    const measure = new WebMolKit.OutlineMeasurement(0, 0, this.policy.data.pointScale);
    const layout = new Mixtures.ArrangeMixture(mixture, measure, this.policy);
    layout.arrange();
    const MAX_W = 200, MAX_H = 200;
    const scale = Math.min(1, Math.min(MAX_W / layout.width, MAX_H / layout.height));
    layout.scaleComponents(scale);

    const gfx = new WebMolKit.MetaVector();
    const draw = new Mixtures.DrawMixture(layout, gfx);
    draw.draw();
    gfx.normalise();
    result.gfx = gfx;
  }
}
