/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import './Mixture.sass';
import { observer } from 'mobx-react';
import autobind from 'class-autobind';
import { A } from '@/shared/components/sanitizedTags.js';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Tooltip,
} from '@mui/material';
import CancelOrSubmit from '@/shared/components/CancelOrSubmit.jsx';
import { getRootStore } from '@/stores/rootStore';
import { MixtureEditorPanel } from './MixtureEditorPanel';
import { PasteMixtureDialog } from './PasteMixtureDialog';
import { DetailComponentDialog } from './DetailComponentDialog';
import QuestionIcon from '@/shared/components/icons/QuestionIcon';
import UndoIcon from '@/shared/components/icons/UndoIcon';
import RedoIcon from '@/shared/components/icons/RedoIcon';
import MagnifyIcon from '@/shared/components/icons/MagnifyIcon';
import PlusIcon from '@/shared/components/icons/PlusIcon';
import MinusIcon from '@/shared/components/icons/MinusIcon';

export type Props = {
  mixfileString?: string;
  callbackSave: (mixfileString: string) => void;
  callbackRestore?: () => void; // optional
  callbackCancel?: () => void; // optional
}

type State = {
  isOpen: boolean;
}

@observer
export default class MixtureEditorDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.store.setMixture(new Mixtures.Mixture(props.mixfileString ? JSON.parse(props.mixfileString) : null));
    this.store.setSelectedOrigin([]);
    this.store.clearHistory();

    this.state = {
      isOpen: true,
    };

    autobind(this);
  }

  get store() {
    return getRootStore().mixturesStore;
  }

  public render(): JSX.Element {
    return (
      <Dialog
        disablePortal
        className='EditTeamDialog edit-account-object-dialog'
        open={this.state.isOpen}
        PaperProps={{ className: 'main-dialog-paper' }}
        maxWidth={false}
        onPaste={this.handleSystemPaste}
      >
        {this.renderContent()}
      </Dialog>
    );
  }

  private renderContent(): JSX.Element {
    const { store, props } = this;

    const panelWidth = Math.round(document.documentElement.clientWidth - 100);
    const panelHeight = Math.round(document.documentElement.clientHeight - 210);

    const tooltipHelp = (
      <Typography>
        TAB to create new components.<br/>
        ENTER to edit component details.<br/>
        Shift-ENTER or double click to edit structure.<br/>
        Right click for context menu.<br/>
        Just start typing to search.
      </Typography>
    );

    const tooltipUndo = (
      <Typography>
        Undo most recent change.
      </Typography>
    );

    const tooltipRedo = (
      <Typography>
        Redo most recent change.
      </Typography>
    );

    const tooltipZoom = (
      <Typography>
        Zoom to fit the whole mixture.
      </Typography>
    );

    const tooltipPlus = (
      <Typography>
        Zoom in.
      </Typography>
    );

    const tooltipMinus = (
      <Typography>
        Zoom out.
      </Typography>
    );

    return (
      <>
        <DialogTitle className='muiDialog-title' style={{ marginBottom: 0 }}>
          Edit Mixture
        </DialogTitle>
        <DialogContent className="MixtureEditor-dialogmain">
          <MixtureEditorPanel
            store={store}
            width={panelWidth}
            height={panelHeight}
            />
        </DialogContent>
        <DialogActions className="project__action">
          <div className="MixtureEditor-twosides" style={{ alignItems: 'center' }}>
            <div className="MixtureEditor-edit-hbox">
              <div
                id="MixtureEditor-mainbutton-question"
                className="MixtureEditor-menubutton MixtureEditor-hoveronly"
                >
                <Tooltip
                  title={tooltipHelp}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><QuestionIcon width="24" height="24" color="#3399FF"/></div>
                </Tooltip>
              </div>
              <div className="MixtureEditor-vertbarrier"/>
              <div
                id="MixtureEditor-mainbutton-undo"
                className={`MixtureEditor-menubutton MixtureEditor-${store.canUndo() ? 'clickable' : 'unclickable'}`}
                onClick={this.handleUndo}
                >
                <Tooltip
                  title={store.canUndo() && tooltipUndo}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><UndoIcon width="24" height="24" disabled={!store.canUndo()}/></div>
                </Tooltip>
              </div>
              <div
                id="MixtureEditor-mainbutton-redo"
                className={`MixtureEditor-menubutton MixtureEditor-${store.canRedo() ? 'clickable' : 'unclickable'}`}
                onClick={this.handleRedo}
                >
                <Tooltip
                  title={store.canRedo() && tooltipRedo}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><RedoIcon width="24" height="24" disabled={!store.canRedo()}/></div>
                </Tooltip>
              </div>
              <div className="MixtureEditor-vertbarrier"/>
              <div
                id="MixtureEditor-mainbutton-zoomfit"
                className="MixtureEditor-menubutton MixtureEditor-clickable"
                onClick={this.handleZoomFit}
                >
                <Tooltip
                  title={tooltipZoom}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><MagnifyIcon width="24" height="24"/></div>
                </Tooltip>
              </div>
              <div
                id="MixtureEditor-mainbutton-zoomin"
                className="MixtureEditor-menubutton MixtureEditor-clickable"
                onClick={this.handleZoomIn}
                >
                <Tooltip
                  title={tooltipPlus}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><PlusIcon width="24" height="24"/></div>
                </Tooltip>
              </div>
              <div
                id="MixtureEditor-mainbutton-zoomout"
                className="MixtureEditor-menubutton MixtureEditor-clickable"
                onClick={this.handleZoomOut}
                >
                <Tooltip
                  title={tooltipMinus}
                  arrow
                  disableFocusListener
                  placement="top"
                  >
                  <div><MinusIcon width="24" height="24"/></div>
                </Tooltip>
              </div>
            </div>
            <div className="MixtureEditor-extrabuttons">
              {props.callbackRestore && (
                <A
                  className="cancel"
                  onClick={this.handleRestore}
                  href="#"
                  style={{ color: '#AA6666', margin: '0px 20px 0px 0px', whiteSpace: 'nowrap' }}
                  >
                  Restore original
                </A>
              )}
              <CancelOrSubmit
                green={true}
                onCancel={this.handleCancel}
                onSubmit={this.handleSubmit}
              >
                Save
              </CancelOrSubmit>
            </div>
          </div>
        </DialogActions>

        <PasteMixtureDialog
          store={store}
          isOpen={store.isClipboardOpen}
          initText={store.clipboardText}
          />
        <DetailComponentDialog
          store={store}
          isOpen={store.isDetailOpen}
          origin={store.detailOrigin}
          />
      </>
    );
  }

  private handleRestore = (): void => {
    this.setState({ isOpen: false });
    this.props.callbackRestore();
  };

  private handleCancel = (): void => {
    this.setState({ isOpen: false });
    const { callbackCancel } = this.props;
    if (callbackCancel) callbackCancel();
  };

  private handleSubmit = (): void => {
    this.setState({ isOpen: false });
    this.props.callbackSave(this.store.mixture.serialise());
  };

  private handleUndo = (): void => {
    this.store.actionUndo();
  };

  private handleRedo = (): void => {
    this.store.actionRedo();
  };

  private handleZoomFit = (): void => {
    this.store.actionZoomFit();
  };

  private handleZoomIn = (): void => {
    this.store.actionZoomMag(1);
  };

  private handleZoomOut = (): void => {
    this.store.actionZoomMag(-1);
  };

  private handleSystemPaste = (event: React.ClipboardEvent): void => {
    const { store } = this;
    if (store.shouldBogartClipboard()) {
      const text = event.clipboardData.getData('text');
      store.openPasteClipboard(text);
      event.preventDefault();
    }
  };
}
