import { StringOrNumber } from '@/types';

type ID = StringOrNumber;

export interface FieldDefinitionValue {
  id: ID;
  protocol_id: number;
  protocol_field_definition_id: number;
  float_value?: number;
  text_value?: string;
  date_value?: string;
  uploaded_file_id?: ID;
  pick_list_value_id?: StringOrNumber;
  batch_link_id?: number | null;
  location_id?: number | null;
}

export enum FieldDataType {
  Float = 'Float', // not used here
  Number = 'Number',
  Text = 'Text',
  LongText = 'LongText',
  Date = 'Date', // not used here
  File = 'File',
  PickList = 'PickList',
  BatchLink = 'BatchLink',
  Location = 'Location',
  Boolean = 'Boolean',
  Project = 'Project',
}

export interface FieldPickValue {
  id: StringOrNumber;
  _destroy?: boolean;
  value: string;
  hidden: boolean;
  created_at: string;
  updated_at: string;
  fields_count: number;
  deletable: boolean;
  is_single_use?: boolean;
}

export interface FieldDefinition { // protocol, run, etc
  _destroy?: number;
  _required_group_label?: string;
  id: StringOrNumber;
  name: string;
  display_order?: number;
  data_type_name?: FieldDataType;
  unique?: boolean; // eln fields use unique, I guess
  unique_value?: boolean;
  required_group_number?: number;
  required?: boolean;
  overwritable?: boolean;
  overwrite_disabled?: boolean;
  is_sample_id?: boolean;
  is_single_use?: boolean;
  disabled?: boolean;
  pick_list_values?: FieldPickValue[];
  temp_group_number?: number;
  inventory_event_fields_count?: number;
  inventory_sample_fields_count?: number;
  batch_fields_count?: number;
  protocol_fields_count?: number;
  molecule_fields_count?: number;
  amount_type?: string;
  is_display_identifier?: boolean;
}
