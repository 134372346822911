import {
  FieldType,
  InventorySearchViewAPIProps,
  ResultColumnsEditorProps,
} from './types';

export function buildFieldDefinitions(
  inventoryFieldDefinitions: InventorySearchViewAPIProps['custom_field_definitions'],
): ResultColumnsEditorProps['availableFilters'] {
  const byFieldThenVault: ResultColumnsEditorProps['availableFilters']['byFieldThenVault'] =
    { Sample: {}, Event: {}, Molecule: {} };
  const byFieldThenSelectValue = { Sample: {}, Event: {}, Molecule: {} };

  Object.keys(inventoryFieldDefinitions).forEach((field_type) => {
    inventoryFieldDefinitions[field_type].forEach(
      ({ vault_id, id, ...fieldDefinition }) => {
        if (!byFieldThenVault[field_type][vault_id]) {
          byFieldThenVault[field_type][vault_id] = [];
        }
        byFieldThenVault[field_type][vault_id].push({
          ...fieldDefinition,
          id,
          field_type,
          vault_id,
        });
        byFieldThenSelectValue[field_type][id] = {
          ...fieldDefinition,
          id,
          field_type,
        };
      },
    );
  });
  return {
    all: Object.keys(inventoryFieldDefinitions)
      .map((fieldType) => {
        const fieldDefinitions =
          inventoryFieldDefinitions[fieldType as FieldType];
        return fieldDefinitions.map((fieldDefinition) => ({
          ...fieldDefinition,
          field_type: fieldType as FieldType,
        }));
      })
      .flat(),
    byFieldThenSelectValue,
    byFieldThenVault,
  };
}
